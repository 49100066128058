<template>
  <div class="app-container">
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          <div style="display: flex; width: 80%">
           <el-select v-model="operatorType" filterable placeholder="请选择操作平台" clearable @change="change"  @clear ="clear" style="margin-right:40px;width:300px">
    <el-option
      v-for="item in res"
      :key="item.operatorType"
      :label="item.name"
      :value="item.operatorType"
    >
    </el-option>
  </el-select>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-table :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="tables"
        class="emp_table"
        v-if="list"  >

      <el-table-column label="操作平台" align="center" prop="operatorType" />
      <el-table-column label="系统模块" align="center" prop="title" />
      <el-table-column label="操作类型" align="center" prop="businessType"/>  
      <el-table-column label="请求方式" align="center" prop="requestMethod" />
      <!-- <el-table-column label="请示方法" align="center" prop="method" /> -->
      <el-table-column label="操作人员" align="center" prop="operName" width="100" :show-overflow-tooltip="true"   />
      <!-- <el-table-column label="操作地址" align="center" prop="operIp" width="100" :show-overflow-tooltip="true" /> -->

      <el-table-column label="操作状态" align="center" prop='status' width="100" />
      <el-table-column label="操作日期" align="center" prop='operTime' width="180">
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.pageSize"
      @pagination="getLogList"
    />

  </div>
</template>

<script>
import { getLogList} from "../../request/http";

import {getLocalTime} from "@/filters"
export default {
  name: "uLog",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      operatorType:null,
      // 表格数据
      list: [],
      res:[
          {name:'后台',operatorType:1},
          {name:'移动',operatorType:2}
      ],
      queryParams:{
        page: 1,
        pageSize: 10,}
        
    };
  },
  created() {
    this.getLogList();
  },
  methods: {
    /** 查询登录日志 */
    async getLogList() {
      try {
        const {data,count} = await getLogList(this.queryParams)
    for( var i in data){
 if(data[i].businessType == 1) {
        data[i].businessType = "新增"
      }
      else if ( data[i].businessType== 2) {
         data[i].businessType="修改"
      }
       else if ( data[i].businessType== 3) {
        data[i].businessType ="删除"
      }
       else if (data[i].businessType == 4) {
        data[i].businessType ="获取"
      }
       else if ( data[i].businessType== 5) {
        data[i].businessType ="登录"
      } 
      if(data[i].status == 0) {
        data[i].status ="成功"
      } else{
         data[i].status ="失败"
      }
      if(data[i].operatorType==1){
        data[i].operatorType="后台用户"
      } else if (data[i].operatorType==2){
        data[i].operatorType ="手机端用户"
      }
      if(data[i].operTime){
       data[i].operTime= getLocalTime(data[i].operTime)
      }
       if(!data[i].title){
        data[i].title='登录'
      }
    }
     this.list = data
      this.total = count
      this.loading = false

      } catch (error) {
        console.log(error);
        
      }
    
    },
   async change(val){
        console.log(val);
        this.operatorType = val
        this.searchgetLogList()
    },
      clear(){
    this.queryParams={}

    },
   async searchgetLogList () {
    this.queryParams = {
 page: 1,
 pageSize: 10,
 operatorType:this.operatorType
    }
    
      let {data,count} = await getLogList(this.queryParams);
       for( var i in data){
 if(data[i].businessType == 1) {
        data[i].businessType = "新增"
      }
      else if ( data[i].businessType== 2) {
         data[i].businessType="修改"
      }
       else if ( data[i].businessType== 3) {
        data[i].businessType ="删除"
      }
       else if (data[i].businessType == 4) {
        data[i].businessType ="获取"
      }
       else if ( data[i].businessType== 5) {
        data[i].businessType ="登录"
      } 
      if(data[i].status == 0) {
        data[i].status ="成功"
      } else{
         data[i].status ="失败"
      }
      if(data[i].operatorType==1){
        data[i].operatorType="后台用户"
      } else if (data[i].operatorType==2){
        data[i].operatorType ="手机端用户"
      }
      if(data[i].operTime){
       data[i].operTime= getLocalTime(data[i].operTime)
      }
      if(!data[i].title){
        data[i].title='登录'
      }
    }
      this.list = data;
      this.total = count
    
  }
}
}
</script>

